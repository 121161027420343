<template>
  <f7-page name="form">
    <f7-navbar title="Nový odvoz odpadu" innerClass="text-white" back-link="Návrat" theme-dark></f7-navbar>
      <div class="loading" v-if="loading">
          <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
  <circle fill="#007aff" stroke="none" cx="6" cy="50" r="6">
    <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin="0.1"/>
  </circle>
              <circle fill="#007aff" stroke="none" cx="30" cy="50" r="6">
    <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin="0.2"/>
  </circle>
              <circle fill="#007aff" stroke="none" cx="54" cy="50" r="6">
    <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin="0.3"/>
  </circle>
</svg>

      </div>
      <div v-if="message && !loading">
         <div class="message-appear-from-top">{{message}}</div>
      </div>
      <div v-else>
    <f7-block-title v-show="formPage < 4" v-if="selectedWaste">Poradové číslo  <div class="eid"><span>{{store.data.uuid}}</span><span>{{year}}</span><span class="last">{{storage.nextorder}}</span></div></f7-block-title>
    <f7-list no-hairlines-md v-show="formPage === 0">

      <f7-block-title class="title-small ">Typ odpadu</f7-block-title>
      <f7-list media-list class="waste-type">
        <f7-list-item v-for="ro in wastes"
                radio
                      :key="'w'+ro.value"
                name="waste"
                      @change="(e) => selectWaste(e,ro.unit)"
                :value="ro.value"
                       :title="ro.wcode"
                :text="ro.label"
        ></f7-list-item>
      </f7-list>

    </f7-list>

     <f7-list v-show="formPage === 1" class="page2">
       <f7-block-title class="title-small">Dodávateľ</f7-block-title>

         <f7-list media-list  >
             <f7-list-item v-for="(ro,ind) in contractors"
                           radio
                           :key="ro.value"
                           @change="selectContractor"
                           name="contractor"
                           :value="ro.value"
                           :title="ro.label"
             ></f7-list-item>
         </f7-list>
         <f7-list media-list v-if="selectedContractor">
             <f7-block-title class="title-small">Prevádzka</f7-block-title>
             <f7-list-item v-for="(ro,ind) in shops"
                           radio
                          :key="ro.value"
                           name="shop"
                           @change="selectShop"
                           :value="ro.value"
                           :checked="selectedShop === ro.value"
                           v-if="selectedContractor === ro.contractor"
                           :title="ro.label"
                           :text="ro.address"
             ></f7-list-item>
         </f7-list>
    </f7-list>
      <f7-list v-show="formPage === 2" class="page3">
      <f7-block-title class="title-small">Kontajner</f7-block-title>
    <f7-list-item v-for="(ro,ind) in containers"
                  radio
                  :key="ro.value"
                  name="container"
                  @change="selectContainer"
                  :value="ro.value"
                  :title="ro.label"
    ></f7-list-item>

          <div class="contamount" v-if="selectedContainer"><span @click="minus">-</span><span class="digit" v-html="contamount"></span><span @click="plus">+</span></div>
  </f7-list>
          <f7-list v-show="formPage === 3" class="page4">
             <f7-block-title class="title-small">Množstvo</f7-block-title>
              <div class="input">
                  <label class="digits">{{amount}} <span>{{selectedUnitWaste}}</span></label>
                  <numericpad
                          :onInput="onInput"
                          :onDelete="onDelete"
                          :onReset="onReset"
                          :show="showKeypad"
                  />
              </div>
          </f7-list>
      <f7-list v-show="formPage === 4">
          <f7-block-title class="title-small">Fotografia</f7-block-title>
         <f7-block>
             <img v-bind:src="imagePreview" v-show="showPreview" class="record"/>
             <camera @clicked="onChildChange"/>

             <div class="input">

                <label class="upfoto">
                        Nahrať foto
                         <input type="file" id="file" ref="file" accept="image/*" class="fileuploader" v-on:change="handleFileUpload()"/>
                          </label>

          </div>
         </f7-block>
      </f7-list>
      <f7-block class="margin-top-none summary" v-show="formPage === 5">
          <f7-block-title class="title-small">Súhrn</f7-block-title>
          <f7-list media-list>
              <f7-list-item title="Poradové číslo" :subtitle="store.data.uuid + year + storage.nextorder"></f7-list-item>
              <f7-list-item title="Typ odpadu" :subtitle="selectedTextWaste" ></f7-list-item>
              <f7-list-item title="Dodávateľ" :subtitle="selectedTextContractor" ></f7-list-item>
              <f7-list-item title="Prevádzka" :subtitle="selectedTextShop" ></f7-list-item>
              <f7-list-item title="Kontajner" :subtitle="contamount + ' x ' + selectedTextContainer" ></f7-list-item>
              <f7-list-item title="Množstvo" :subtitle="amount + ' '+selectedUnitWaste" ></f7-list-item>
              <f7-list-item v-if="showPreview" title="Fotografia"  >
                  <img v-bind:src="imagePreview" v-show="showPreview" class="sample"/></f7-list-item>
               </f7-list>
          <f7-block class="margin-top-none"  >
              {{message}} </f7-block>
          <f7-row tag="p">   <f7-button @click="submit" class="col" fill>Odoslať</f7-button> </f7-row>
    </f7-block>
      </div>
    <f7-toolbar bottom>
      <f7-link  @click="decrease" ><span v-if="formPage > 0"> <i class="f7-icons" style="margin-right:10px; opacity:.4">chevron_left_square</i>Späť</span></f7-link>
      <f7-link @click="increase" v-if="(selectedWaste > 0 && formPage < 5  && selectedContractor > 0 && selectedShop > 0 && amount > 0) ||
      (formPage === 0 && selectedWaste > 0) || (formPage === 1 && selectedContractor > 0 && selectedShop > 0) || (formPage === 2 )
   || (formPage === 3 && amount > 0)
" >Ďalej <i class="f7-icons" style="margin-left:10px; opacity:.4">chevron_right_square</i></f7-link>
  </f7-toolbar>

  </f7-page>
</template>

<script>
    import numericpad from "./numeric-keypad.vue";



    import {getURL} from "../api";
    import {dataService} from "../_services";
    import {authHeader} from "../_helpers";
    import Camera from "./Camera"

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }


    const zeroPad = (num, places) => String(num).padStart(places, '0')
  export default {
    name: "wasteform",
      components: {
          numericpad,Camera
      },
      data: function () {
          return {
              formPage: 0,
              wastes: [],
              image: null, file: '',
              loading:false,
              contractors: [],
              containers:{},
              shops: [], showPreview: false,
              imagePreview: '',
              year: new Date().getFullYear(),
              store: JSON.parse(localStorage.getItem('user')),
              amount: 0,
              selectedContractor: 0,
              selectedWaste: 0,
              contamount:1,
              url: getURL(),
              selectedContainer:0,
              selectedTextContainer: '',
              selectedShop: 0,
              selectedTextWaste: '',
              showKeypad: true,
              imagesend: '',
              message: '',
              selectedUnitWaste: '',
              geo: [],
              selectedTextShop: '',
              selectedTextContractor: ''
          }
      },
    methods: {

        findFirst(obj)
        {

           if(parseInt(obj.contractor) === parseInt(this.selectedContractor) && !this.selectedShop)
           {  let firstShop = {"target" : {"value":obj.value, "labels": [{innerText: obj.label}]}};
           this.selectShop(firstShop);

               return false;
           }

        },
        selectContractor(e)
        {
            this.selectedContractor = e.target.value;
            this.selectedTextContractor = e.target.labels[0].innerText;
this.selectedShop = "";
            this.shops.forEach(obj =>  this.findFirst(obj));

        },
        minus()
        {
            if(this.contamount > 1)
            this.contamount = this.contamount - 1;
        },
        plus()
        {
                this.contamount = this.contamount + 1;
        },
        onChildChange(e)
        {
const pa = this;
            let formData = new FormData();

            this.imagePreview = e;
            formData.append('upload', dataURLtoFile(e, Math.floor(Date.now() / 1000) + '.png'));
            this.axios.post(this.url.API_URL + '/upload/content',
                formData,
                {
                    headers: authHeader(true)
                }
            ).then(function (data) {
                pa.imagesend = data.data[0];
                pa.showPreview = true;
            });
        },
        handleFileUpload(){
            /*
              Set the local file variable to what the user has selected.
            */
            this.file = this.$refs.file.files[0];

            /*
              Initialize a File Reader object
            */
            let reader  = new FileReader();

            /*
              Add an event listener to the reader that when the file
              has been loaded, we flag the show preview as true and set the
              image to be what was read from the reader.
            */
            reader.addEventListener("load", function () {
                this.showPreview = true;
                this.imagePreview = reader.result;
            }.bind(this), false);

            /*
              Check to see if the file is not empty.
            */
            if( this.file ){
                /*
                  Ensure the file is an image file.
                */

                const pa = this;
                if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
                    /*
                      Fire the readAsDataURL method which will read the file in and
                      upon completion fire a 'load' event which we will listen to and
                      display the image in the preview.
                    */
                    let formData = new FormData();
                    this.imgCroppFile = reader.readAsDataURL( this.file );
                    formData.append('upload', this.file);

                    this.axios.post(this.url.API_URL + '/upload/content',
                        formData,
                        {
                            headers: authHeader(true)
                        }
                    ).then(function (data) {
                        pa.imagesend = data.data[0];
                    });
                }
            }
        },
        onInput(key) {
            if(key === 11)
                key = 0;
if(this.amount === 0)
    this.amount = '';
            this.amount = (this.amount+'' + key).slice(0, this.maxLength);
        },
        onDelete() {
            this.amount = (this.amount+'').slice(0, this.amount.length - 1);
        },
        onReset() {
            this.amount = "";
        },
        selectContainer(e)
        {
            this.selectedContainer = e.target.value;
            this.selectedTextContainer = e.target.labels[0].innerText;

        },
        selectShop(e)
        {
            this.selectedShop = e.target.value;
            this.selectedTextShop = e.target.labels[0].innerText;

        },
        selectWaste(e,f)
        {

            this.selectedWaste = e.target.value;
            this.selectedTextWaste = e.target.labels[0].innerText;
            this.selectedUnitWaste = f;

            this.uuid = JSON.parse(localStorage.getItem('user')).data.uuid;

            dataService.axiosFetch("nextorder/" + this.uuid + "/" + e.target.value).then(results => {
            this.storage.nextorder = zeroPad(results[0].c, 4);  });
        },
        changeAmount(e)
        {console.log(e)},
        press(key) {
           if(key === 'del')
           this.amount = this.amount.slice(0, -1);
           else
           this.amount = this.amount === 0 ? key : this.amount + key;
        },
      increase()
      {
        this.formPage++;

      },
      decrease()
      {
        this.formPage--;

      },
      submit()
      {
          const self = this;
          const router = self.$f7router;
          let wastedata = {"uuid" : this.store.data.uuid, "image": this.imagesend,"amount": this.amount , "internalID" : this.store.data.uuid + this.year + this.storage.nextorder,"title" : 'Odber', "waste_code" : this.selectedWaste ,"containeramount": this.contamount, "contractor" : this.selectedContractor , "shop" : this.selectedShop , "container" : this.selectedContainer ,  "geo" :  this.geo[0]+":"+this.geo[1]}
        this.loading = true;
         dataService.axiosPost("newwastesend", wastedata).then(results => {
         if(results.data.success)
         { setTimeout(() => {
             router.navigate("/home/")
         }, 2000)
             this.loading = false;
         this.message = "Úspešne odovzdané.";
         }

          });
      }
    },
    mounted() {


let self = this;

        this.$getLocation({enableHighAccuracy: true})
            .then(coordinates => {
                self.geo =[coordinates.lat,coordinates.lng]

                dataService.axiosFetch("content/shop",coordinates.lat+":"+coordinates.lng).then(results => {
                    var roleops = [];


                    results.forEach(obj => { roleops.push({"value" : obj.id , "contractor": obj.contractor, "label" : obj.title + " - " + (Math.round(obj.distance * 100) / 100).toFixed(2) + "km","address": obj.address + " (" +obj.latitude + ":"+obj.longitude+")"})});
    this.shops = roleops;

                });
            });

        dataService.axiosFetch("drivercontent","waste").then(results => {
            var roleops = [];

            results.forEach(obj => {
            roleops.push({"value" : obj.id , "label" : obj.title,"wcode" : obj.wcode,"unit" : obj.unit});

            });
            this.wastes = roleops;
        });
        dataService.axiosFetch("content","container").then(results => {
            var roleops = [];

            results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
            this.containers = roleops;
        });

        dataService.axiosFetch("content","contractor").then(results => {
            var roleops = [];
            results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });
            this.contractors = roleops;
        });

    }
  }
</script>
<style scoped>
    .summary >>> .item-subtitle
    {font-size:20px;}
    .summary >>> .item-title
    {font-size:14px;color: #6e6e6e
    }
    .camera-modal {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: white;
        z-index: 10;
    }
    .camera-stream {
        width: 100%;
        max-height: 100%;
    }div.container img{
         max-width: 100%;

     }
    svg {
        width: 100%;
        margin:0 auto;
        height: 100px;
        margin: 20px;
        display: block;
    }
    .page4
    {margin-top:0;}

    .page4 .title-small
    {margin-bottom:0;   }
    .contamount
    {width:250px;display:block;

    margin:0 auto;height:80px;border:3px solid black; text-align:center;margin-top:30px;}
    .contamount span{
        float:left;width:50px;text-align:center;font-size:40px;height:80px;line-height:80px;
    }

    .contamount span:not(.digit):hover
    {cursor:pointer;background-color: #eee}
    .contamount .digit
    {display:block;width:146px;text-align:center;border:2px solid #1c1c1d;border-top:0;border-bottom:0;}
    .contamount .digit:after
    {content:"ks";margin-left:6px;
    font-size:16px;}
    .block .input:after
    {display:none}

    .upfoto
    {display:block; padding:10px 25px; margin:0 auto;text-align:center;border:2px solid #007aff;
        color:#007aff;
    }
    .cell
    {text-align:center}
    .cell .title-smaller
    {text-align:center;margin:0 auto;display:table;padding:10px;line-height:30px;margin-top:-50px;margin-bottom:30px;border:2px solid #afafaf;width:auto;}
.message-appear-from-top
{padding-top:30px;text-align:center;
font-size:1em;}
.record
{width:100px;position:absolute;top:-50px;right:20px}
    .sample
    {width:100px}
</style>
