import { render, staticRenderFns } from "./deliver.vue?vue&type=template&id=fc2815aa&scoped=true&"
import script from "./deliver.vue?vue&type=script&lang=js&"
export * from "./deliver.vue?vue&type=script&lang=js&"
import style0 from "./deliver.vue?vue&type=style&index=0&id=fc2815aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc2815aa",
  null
  
)

export default component.exports