<template>

  <f7-page name="home" class="home-page">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false" theme-dark>
        <f7-nav-title sliding title="EcolTrade s.r.o." :subtitle="store.data.first_name +' ' + store.data.last_name"></f7-nav-title>
     </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
       </f7-toolbar>
    <!-- Page content-->



    <f7-list class="main-menu">
        <f7-list-item link="/form/" class="text-align-center" title="Odber odpadu">
            <f7-icon  slot="title" style="opacity:.4;margin-left:-15px;margin-right:25px;float:left"><i class="f7-icons" style="opacity:.4">purchased</i></f7-icon> </f7-list-item>
        <f7-list-item link="/deliver/" class="text-align-center" title="Odovzdávka odpadu">
            <f7-icon  slot="title" style="opacity:.4;margin-left:-15px;margin-right:25px;float:left"><i class="f7-icons"  style="opacity:.4">arrow_turn_down_right</i></f7-icon>
        </f7-list-item>
        <f7-list-item link="/about/" title="História odvozov">
            <f7-icon  slot="title" style="opacity:.4;margin-left:-15px;margin-right:25px;float:left"><i class="f7-icons" style="opacity:.4">square_list</i></f7-icon>
        </f7-list-item>
    <f7-list-item link="/settings/" title="Nastavenia">
        <f7-icon  slot="title" style="opacity:.4;margin-left:-15px;margin-right:25px;float:left"><i class="f7-icons" style="opacity:.4">gear_alt</i></f7-icon>
    </f7-list-item>
    <f7-list-item title="Odhlásiť" link="#" @click="logout">
        <f7-icon  slot="title" style="opacity:.4;margin-left:-15px;margin-right:25px;float:left"><i class="f7-icons" style="opacity:.4">square_arrow_right</i></f7-icon>
    </f7-list-item>
    </f7-list>
      <f7-toolbar bottom>
          <span style="display:block;color:#a0a0a0;position:absolute;right:15px;float:right;font-size:12px;">v.1.5</span>
      </f7-toolbar>
  </f7-page>
</template>

<script>


  export default {
  data() {
    return {
      firstname: null,
      lastname: null,
      uuid: null,
      year: new Date().getFullYear(),
      store: JSON.parse(localStorage.getItem('user'))
    }
  },
methods: {
logout() {
localStorage.removeItem('user');
    localStorage.removeItem("vrs_");
    this.storage.style = false;
    this.$f7.views.main.router.navigate('/', {reloadCurrent: true});
},

},
mounted() {
  if(localStorage.getItem('user'))
  {
      if(!this.storage.style)
      {this.storage.style =  JSON.parse(localStorage.getItem('user')).data.style;}


}
  }
}
</script>
<style>
    .main-menu .item-title
    {
        padding-right:0;
    }

    @media (max-width: 330px) {
        .main-menu
        {margin-top:10px;margin-bottom:0;}
        .main-menu .item-title i
        {margin-right:10px!important;
        font-size:23px;}

    }
</style>
